import React from 'react'
import { FiTwitter,
        FiYoutube,
        FiGithub,
        FiInstagram} from "react-icons/fi"
import {Link} from 'react-scroll'

function Footer() {
    return (
        <div className=" flex flex-col-reverse  justify-center items-center
                         mt-10 h-auto min-w-full bg-gradient-to-t from-gray-800 to-gray-600">
        
        <div className="inline-block p-3 space-y-2">

        
            <ul 
            className="flex flex-row space-x-6 text-white text-xl md:text-3xl">
                <a href="https://twitter.com/psyredofficial"><FiTwitter/></a>
                <a href="https://www.youtube.com/channel/UCwWeZ45UmaPUApHV8PiBANA"><FiYoutube/></a>
                <a href="https://github.com/PSYRED"><FiGithub/></a>
                <a href="https://www.instagram.com/psyredofficial/"><FiInstagram/></a>
            </ul>
      


      

            <ul className=" flex flex-row space-x-3
                            font-semibold 
                            text-gray-500
                            text-xl
                            ">
                
                <Link
                className="transition duration-500 ease-in-out hover:text-gray-300"
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                >home
                </Link>

                <Link
                className="transition duration-500 ease-in-out hover:text-gray-300"
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                >About
                </Link>

                <Link
                className="transition duration-500 ease-in-out hover:text-gray-300"
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                >contact
                </Link>
            </ul>
      

           <h3 
           className="font-lora text-base md:text-lg text-gray-300">
               Psyred © 2021 . All rights reserved.</h3>

               </div>
        </div>
    )
}

export default Footer
