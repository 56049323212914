import React from 'react'
import security from '../assets/svg/security.svg'
import devices from '../assets/svg/Devices.svg'
import deliveries from '../assets/svg/deliveries.svg'

function About() {
    return (
        <div className="min-h-screen w-full " id="about">
       
             {/* Feature #1 */}
      <div class="relative mt-20 lg:mt-52">
        <div class="container flex flex-col lg:flex-row items-center justify-center gap-x-24">
        {/* Image */}
          <div class="flex flex-1 justify-center z-10 mb-10 lg:mb-0">
            <img
              class="w-2/4 h-2/4 md:w-3/5 md:h-2/5"
              src={devices}
              alt=""
            />
          </div>
         {/* Content */}
          <div class="flex flex-1 flex-col items-center lg:items-start">
            <h1 class="text-4xl font-notosans font-bold text-bookmark-blue">The future of <br/>software delivery</h1>
            <p class="text-gray-800 font-semibold font-lora my-4 lg:pr-2 text-center lg:text-left sm:w-3/4 lg:w-full">
            Globally, the software industry has been in crucial demand over the years, 
            with over $237 Billion in revenue there is no doubt that the functionality of software is key to enterprise success.With the pandemic changing the working mode all over the world, 
            companies all over the world must ensure that their employees are engaged and connected as they work from home.Our goal is to deliver the ideal software, with accumulating level of efficiency.
            </p>
            
          </div>
        </div>
      {/* Rounded Rectangle */}
        <div
          class="
          bg-white
            hidden
            lg:block
            overflow-hidden
            bg-bookmark-purple
            rounded-r-full
            absolute
            h-80
            w-2/4
            -bottom-24
            -left-36
          "
        ></div>
      </div>
           
    
     
      
      {/* Features 2 */}
      <div class="relative mt-20 lg:ml-14 lg:mt-52">
        <div class="container flex flex-col lg:flex-row-reverse items-center justify-center gap-x-24">
          
          {/* Image */}
          <div class="flex flex-1 justify-center z-10 mb-10 lg:mb-0">
            <img
              class="w-2/4 h-2/4 md:w-3/5 md:h-2/5"
              src={deliveries}
              alt=""
            />
          </div>
         
          {/* Content */} 
          <div class="flex flex-1 flex-col items-center lg:items-start subpixel-antialiased">
            <h1 class="text-4xl font-notosans font-bold text-bookmark-blue">Keeping users <br/> Happy</h1>
            <p class="text-gray-800 font-semibold font-lora my-4 lg:pr-2 text-center lg:text-left sm:w-3/4 lg:w-full">
            User contentedness is what Psyred pursues,Generally software users have an impact on the software it self,
             as software ratings is a clear illustration on application success,
              50% of mobile users won’t consider an app with a 3-star rating.
               The number decreases even more.
               The objective is to make software as user friendly as possible,
                with all kinds of services client's are willing to give.
            </p>
          </div>
        </div>
        {/* Rounded Rectangle */} 
        <div
          class="
            hidden
            lg:block
            overflow-hidden
            bg-white
            rounded-l-full
            absolute
            h-80
            w-2/4
            -bottom-24
            -right-36
          "
        ></div>
      </div>
    


     {/* Feature #3 */}
      <div class="relative mt-20 lg:mt-52">
        <div class="container flex flex-col lg:flex-row items-center justify-center gap-x-24">
        {/* Image */}
          <div class="flex flex-1 justify-center z-10 mb-10 lg:mb-0">
            <img
              class="w-2/4 h-2/4 md:w-3/5 md:h-2/5"
              src={security}
              alt=""
            />
          </div>
         {/* Content */}
          <div class="flex flex-1 flex-col items-center lg:items-start">
            <h1 class="text-4xl font-notosans font-bold text-bookmark-blue">Secure & <br/> Reliable</h1>
            <p class="text-gray-800 font-semibold font-lora my-4 lg:pr-2 text-center lg:text-left sm:w-3/4 lg:w-full">
            Your data is essential to Psyred. With different kinds of companies seeking to take advantage in its own market, 
            Data security is also in more demand and is also high-priced. Psyred handles that for you by delivering functional and secure software ready to compete for the market you target.
            Our target is users for your market.
            </p>
            
          </div>
        </div>
      {/* Rounded Rectangle */}
        <div
          class="
          bg-white
            hidden
            lg:block
            overflow-hidden
            bg-bookmark-purple
            rounded-r-full
            absolute
            h-80
            w-2/4
            -bottom-24
            -left-36
          "
        ></div>
      </div>
    


        </div>
        
    )
}

export default About
