import React , {useEffect, useState} from 'react'
import {Link} from 'react-scroll'

function Home() {
    const [animate, setAnimate] = useState(false)
    useEffect(() => { setAnimate(true); console.log("animate is " + animate)}, [])
    return (
        <div className="flex justify-center items-center flex-col md:justify-around" id="home">

            <div className=" mt-28 text-center h-screen antialiased space-y-6">

                <img 
                className={`${animate ? 'scale-100' : '-translate-y-full'} 
                w-60 md:w-128 md:p-20 transform transition duration-1000 ease-in-out`} 
                src="/logo/logo.png" alt="Psyred logo"/>
                <br/>
                <h2 className="text-lg font-lora font-semibold">Study the internet.</h2>
                <br/>
                
                <Link
                className={`${animate ? 'scale-100' : '-translate-y-full'} font-notosans text-base mb-14 text-white
                inline-block bg-gray-500 px-10 py-5 rounded-full 
                shadow-2xl opacity-80 hover:opacity-100  text-ls tracking-wide transform transition duration-1000 ease-in-out`}
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                >LEARN MORE
                </Link>
            </div>
            
        </div>
    )
}

export default Home
