import React from 'react'
import {Link} from 'react-scroll'

function Navigation() {
    return (
        <div>
        <Link
        activeClass="active"
        to="home"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
        >
        </Link>

        <Link
        activeClass="active"
        to="about"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
        >
        </Link>

        <Link
        activeClass="active"
        to="contact"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
        >
        </Link>

        </div>
    )
}

export default Navigation
