import React, {useEffect, useState} from 'react';
import Footer  from './components/Footer';
import Navigation from './components/Navigation';
import Home from './Pages/Home'
import About from './Pages/About'
import Contact from './Pages/Contact';

function App() {
  const [animate, setAnimate] = useState(false)
  useEffect(() => { setAnimate(true); console.log("animate is " + animate)}, [])
  return (
    <div className=" min-h-screen min-w-full bg-gradient-to-r from-red-500 via-red-600 to-red-800">
     {/* Navigation is IMPORTANT */}
     <body>
       <Navigation/>
      <Home/>
      <About/>
      <Contact/>
     </body>
     
     <footer 
     className={`${animate ? 'scale-100' : 'translate-y-full'} 
     mt-10 transform transition duration-1000 ease-in-out`}>
       <Footer/>
     </footer>
       
    </div>
  );
}

export default App;

