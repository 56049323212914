import React from 'react'
import { FiTwitter,
    FiYoutube,
    FiGithub,
    FiInstagram} from "react-icons/fi"
    import emailjs from 'emailjs-com'

function Contact() {

    function sendEmail(e) {
        e.preventDefault()
        emailjs.sendForm('service_kgcx6ef', 'template_30mgpdj', e.target, 'user_Hf6XcycwOPG3IIijlTlod'
        ).then(res=>{
            console.log(res);
        }).catch(err => console.log(err));

    }

    


    return (
        <div className="min-h-screen w-full antialiased" id="contact">
            
        <div className=" pt-14 flex flex-col justify-around items-center">
            <h2 className="text-gray-700 font-notosans font-bold text-3xl" >Contact Us</h2>
            <br/>
            <h2 className=" text-gray-200 font-notosans font-semibold text-base">Follow us or contact us @</h2>
            <br/>
                
                <div className="flex flex-col justify-around items-center">
                    <ul className="flex flex-row space-x-5 text-gray-200 text-4xl ">
                
                <a className=" hover:-translate-y-1.5 transform transition" href="https://twitter.com/psyredofficial"><FiTwitter/></a>
                <a className=" hover:-translate-y-1.5 transform transition" href="https://www.youtube.com/channel/UCwWeZ45UmaPUApHV8PiBANA"><FiYoutube/></a>
                <a className=" hover:-translate-y-1.5 transform transition" href="https://github.com/PSYRED"><FiGithub/></a>
                <a className=" hover:-translate-y-1.5 transform transition" href="https://www.instagram.com/psyredofficial/"><FiInstagram/></a>
                
                </ul>

                <div className="flex flex-col p-4 space-y-2 text-gray-200 text-lg " >
                    
                    <h2 className="font-notosans" >For acquaintance:</h2>
                    <h2 className="font-lora text-right text-gray-800 font-semibold">admin@psyred.com</h2>
                    <h2 className="font-notosans" >For support:</h2>
                    <h2 className="font-lora text-right text-gray-800 font-semibold">support@psyred.com</h2>
                    <h2 className="font-notosans">TELEPHONE:</h2>
                    <h2 className="font-lora text-right text-gray-800 font-semibold">+48516991476</h2>
                    <h2 className="font-notosans">ADDRESS:</h2>
                    <h2 className="font-lora text-right text-gray-800 font-semibold">Bydgoszcz, Poland <br/> Stawowa 47/76, 85-219</h2>
               
                </div>
                
                </div>
                
                
                <div className="flex flex-col">
                    <h2 className="text-gray-50 pt-6 font-notosans font-bold text-3xl" >share a message</h2>



                <form 
                className="flex flex-col space-y-4 pt-8"
                onSubmit={sendEmail}
                >
                    <input 
                    className="p-3 rounded-xl font-semibold font-notosans" 
                    name="user_email"
                    placeholder="email"/>

                    <input 
                    className="p-3 rounded-xl font-semibold font-notosans" 
                    name="name"
                    placeholder="name"/>

                    <textarea 
                    className="p-3 rounded-2xl font-medium font-notosans focus:border-blue-500" 
                    name="message"
                    placeholder="MESSAGE" />

                    <button 
                    className="font-notosans text-base mb-14 text-white
                                inline-block bg-gray-500 px-10 py-5 rounded-full 
                                shadow-2xl opacity-80 hover:opacity-100  
                                text-ls tracking-wide transform transition duration-1000 ease-in-out"
                    type="submit"
                    onSubmit={sendEmail}
                >Submit</button>

                </form>



                </div>
                

        </div>

        </div>
    )
}

export default Contact
